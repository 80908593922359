import '../plugins/topojson';
import 'select2';
import 'select2/dist/css/select2.min.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css';

const { departamentos } = require('../data/departamentos');
const { municipios } = require('../data/municipios');
const { capitales } = require('../data/capitalesMunicipales');

const noExp = (num) => {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (_a, b, c, d, e) {
    if (e < 0) {
      return b + '0.' + Array(1 - e - c.length).join('0') + c + d;
    } else {
      return b + c + d + Array(e - d.length + 1).join('0');
    }
  });
};

const round = (number, decimals = 2) =>
  (+(Math.round(+(noExp(number) + 'e+2')) + 'e-2')).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

let dptoLayer,
  muniLayer,
  selectedLayer = null;
let listadoEtas;
let electosDepartamento,
  electosMunicipio,
  GESTION = 2021;

const latLng = [-17.403839, -66.170288];
const zoom = 6;

let dataDepartamentos = {};
let dataMunicipios = {};

const map = L.map('map').setView(latLng, zoom);

L.tileLayer(
  'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
  {
    maxZoom: 10,
    attribution: `<a href="https://www.openstreetmap.org/copyright" target="_blank">OSM</a>
      | Limites referenciales, simplificados con fines estadísticos &copy; <a href="https://www.sea.gob.bo/" target="_blank">SEA</a> 2021`,
    id: 'mapbox/light-v9',
    tileSize: 512,
    zoomOffset: -1,
  }
).addTo(map);

const infoControl = L.control();

infoControl.onAdd = function (map) {
  this._div = L.DomUtil.create('div', 'info-control');
  this.update();
  return this._div;
};

infoControl.update = function (data) {
  // if (data && data.segundaVuelta == 'SEGUNDA VUELTA') {
  //   this._div.innerHTML = `<strong>${data.eta}</strong>
  //   <div><em>IRAN A SEGUNDA VUELTA</em></div>
  //   `;
  // } else
  if (data) {
    this._div.innerHTML = `<strong>${data.eta}</strong>
    <div><b>${data.partido}: </b> ${data.porcentaje}%</div>
    <div><b>${data.titulo}: </b> ${data.autoridad} </div>
    `;
  } else {
    this._div.innerHTML = '';
  }
};

infoControl.addTo(map);

const hoverStyle = {
  weight: 3,
  color: '#fff',
  // dashArray: '',
  fillOpacity: 0.95,
};

const selectStyle = {
  weight: 6,
  color: '#ff0000',
  fillOpacity: 1,
};

function getColor(eta_id, type) {
  let color, item;
  switch (type) {
    case 'Departamento':
      item = electosDepartamento.find((item) => item.id_eta === '9' + eta_id);

      color = item ? item.color : null;
      break;

    case 'Municipio':
      item = electosMunicipio.find((item) => item.id_eta === eta_id);
      color = item ? item.color : null;
      break;
  }

  color = color ? color : '#111';
  return color;
}

function dptoStyle(feature) {
  return {
    fillOpacity: 0.8,
    color: '#fafafa',
    weight: 2,
    opacity: 1,
    fillColor: getColor(feature.properties.c_ut_dep, 'Departamento'),
  };
}

function muniStyle(feature) {
  return {
    fillOpacity: 0.8,
    color: '#fafafa',
    weight: 2,
    opacity: 1,
    fillColor: getColor(feature.properties.codigomef, 'Municipio'),
  };
}

function makeDeptoLayer() {
  if (dptoLayer) {
    map.removeLayer(dptoLayer);
  }
  // console.log('make-depto');

  dptoLayer = new L.TopoJSON(departamentos, {
    style: dptoStyle,
    onEachFeature: onEachDptoFeature,
  });

  map.addLayer(dptoLayer);
}

// capitalesLayer = new L.TopoJSON(capitales, {
//   // style: dptoStyle,
//   // onEachFeature: onEachFeature
// });

// map.addLayer(capitalesLayer);

function onEachDptoFeature(feature, layer) {
  layer.on({
    mouseover: (e) => {
      const layer = e.target;
      layer.setStyle(hoverStyle);
      displayDeptoControl('9' + e.target.feature.properties.c_ut_dep);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        // layer.bringToFront();
      }
    },
    mouseout: (e) => {
      dptoLayer.resetStyle(e.target);
      infoControl.update();
    },
    click: (e) => {
      map.fitBounds(e.target.getBounds());
      displayDeptoInfo('9' + e.target.feature.properties.c_ut_dep);
      makeMuniLayer(e.target.feature.properties.nom_dpto);
    },
  });
  dataDepartamentos['9' + feature.properties.c_ut_dep] = layer;
}

async function makeMuniLayer(departamento) {
  if (muniLayer) {
    map.removeLayer(muniLayer);
  }

  const { data } = await $.getJSON('api/electos-municipios/', { gestion: GESTION, departamento: departamento });
  electosMunicipio = data;

  muniLayer = new L.TopoJSON(municipios, {
    style: muniStyle,
    filter: function (feature, layer) {
      return departamento ? feature.properties.nom_dpto === departamento : true;
    },
    onEachFeature: onEachMuniFeature,
  });

  map.addLayer(muniLayer);

  return data;
}

function onEachMuniFeature(feature, layer) {
  layer.on({
    mouseover: (e) => {
      const layer = e.target;
      if (selectedLayer != e.target) {
        layer.setStyle(hoverStyle);
      }
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
      displayMuniControl(e.target.feature.properties.codigomef);
    },
    mouseout: (e) => {
      if (selectedLayer != e.target) {
        muniLayer.resetStyle(e.target);
      }
      infoControl.update();
    },
    click: (e) => {
      if (selectedLayer) {
        muniLayer.resetStyle(selectedLayer);
      }
      const layer = e.target;
      map.fitBounds(e.target.getBounds());
      displayMuniInfo(e.target.feature.properties.codigomef);
      layer.setStyle(selectStyle);
      selectedLayer = layer;
      layer.bringToFront();
    },
  });

  dataMunicipios[feature.properties.codigomef] = layer;
}

async function changeGestion(newGestion) {
  GESTION = newGestion;
  electosDepartamento = null;
  electosMunicipio = null;
  const { data } = await $.getJSON('api/electos-departamentos/', { gestion: GESTION });
  electosDepartamento = data;
  makeDeptoLayer();
}

(function () {
  var control = new L.Control({ position: 'topleft' });
  control.onAdd = function (map) {
    var azoom = L.DomUtil.create('button', 'btn-reset');
    azoom.innerHTML = '<i class="fas fa-sync"></i>';
    L.DomEvent.disableClickPropagation(azoom).addListener(
      azoom,
      'click',
      function () {
        resetLayers();
        map.setView(latLng, zoom);
      },
      azoom
    );
    return azoom;
  };
  return control;
})().addTo(map);

function resetLayers() {
  if (muniLayer) {
    map.removeLayer(muniLayer);
  }
  selectedLayer = null;
  $('.info-container').empty();
  $('#select2-eta').val('');
}

async function displayDeptoInfo(idEta) {
  const item = electosDepartamento.find((item) => item.id_eta === idEta);

  if (item) {
    const { data } = await $.getJSON(`api/info-departamento/`, { gestion: GESTION, idEta: idEta });

    const tableElectos = {
      subgobernador: makeTableElectos(data.subgobernador, item.id_sigla, 'SUBGOBERNACIONES'),
      corregidor: makeTableElectos(data.corregidor, item.id_sigla, 'CORREGIDORES'),
      a_territorio: makeTableElectos(data.a_territorio, item.id_sigla, 'ASAMBLEISTAS POR TERRITORIO'),
      a_poblacion: makeTableElectos(data.a_poblacion, item.id_sigla, 'ASAMBLEISTAS POR POBLACION'),
      a_indigena: makeTableElectos(data.a_indigena, item.id_sigla, 'ASAMBLEISTAS INDIGENAS'),
      ejecutivos: makeTableEjecutivos(data.ejecutivos),
      directores: makeTableEjecutivos(data.directores, 'DIRECTORES'),
    };

    const totalPartidoElectos =
      tableElectos.subgobernador.partidoElectos +
      tableElectos.corregidor.partidoElectos +
      tableElectos.a_territorio.partidoElectos +
      tableElectos.a_poblacion.partidoElectos;

    const totalElectos =
      tableElectos.subgobernador.totalElectos +
      tableElectos.corregidor.totalElectos +
      tableElectos.a_territorio.totalElectos +
      tableElectos.a_poblacion.totalElectos;

    const _data = {
      item: item,
      departamento: item.depto,
      gobernador: item.nombre_exautoridad,
      partido: item.sigla,
      porcentaje: item.porcentaje,
      tableElectos: tableElectos,
      gradoGobernabilidad: gradoGobernabilidad(totalPartidoElectos, totalElectos),
      segundaVuelta: item.segunda_vuelta,
      candidatosSegundaVuelta: data.segundaVuelta,
    };

    const _template = _.template($('#deptoInfo').html());

    $('.info-container').html(_template(_data));
    $('[data-toggle="tooltip"]').tooltip();
  } else {
    $('.info-container').html('');
  }
}

async function displayMuniInfo(idEta) {
  const item = electosMunicipio.find((item) => item.id_eta === idEta);
  if (item) {
    const { data } = await $.getJSON(`api/info-municipio/`, { gestion: GESTION, idEta: idEta });

    const datosEconomicos = {
      ...data.datosEconomicos,
      datosGenerales: data.datosGenerales,
      dependenciaFiscal: data.dependenciaFiscal,
      recursosPropios: data.recursosPropios,
      ejecucionGasto: data.ejecucionGasto,
      saldosCuentas: data.saldosCuentas,
      distribucionGasto: data.distribucionGasto,
      normativa: data.normativa,
    };

    const concejales = makeTableElectos(data.concejales, item.id_sigla, 'COMPOSICIÓN DEL CONCEJO');
    const subalcaldes = makeTableEjecutivos(data.subalcaldes, 'SUBALCALDE');
    const ejecutivos = makeTableEjecutivos(data.ejecutivos, 'SECRETARIOS');
    const directores = makeTableEjecutivos(data.directores, 'DIRECTORES');
    const economicos = makeTableDatosEconomicos(datosEconomicos);

    const _data = {
      item: item,
      municipio: `${item.nombreeta} <small>(${item.depto})</small>`,
      alcalde: item.nombre_exautoridad,
      partido: item.sigla,
      porcentaje: item.porcentaje,
      tableConcejales: concejales.$tableElectos,
      gradoGobernabilidad: gradoGobernabilidad(concejales.partidoElectos, concejales.totalElectos),
      tableEjecutivos: ejecutivos,
      tableSubalcaldes: subalcaldes,
      tableDirectores: directores,
      tableDatosEconomicos: economicos,
    };

    const _template = _.template($('#muniInfo').html());

    $('.info-container').html(_template(_data));
    $('[data-toggle="tooltip"]').tooltip();
  } else {
    $('.info-container').html('');
  }
}

function makeTableElectos(data, siglaPartidoGanador, title) {
  const electos = {};
  let partidoElectos = 0,
    totalElectos = 0,
    $tableElectos = '';

  if (data.length) {
    totalElectos = data.length;
    data.forEach((item) => {
      if (!electos.hasOwnProperty(item.id_sigla)) {
        electos[item.id_sigla] = {
          masculino: [],
          femenino: [],
          color: item.color,
          organizacion: item.nombre_organizacion,
        };
      }

      if (item.genero === 'MASCULINO') {
        electos[item.id_sigla].masculino.push(item);
      } else {
        electos[item.id_sigla].femenino.push(item);
      }

      if (item.id_sigla === siglaPartidoGanador) {
        partidoElectos++;
      }
    });
    const _electos = _.template($('#electos').html());
    $tableElectos = _electos({
      total: totalElectos,
      electos: electos,
      title: title,
    });
  }

  return {
    electos,
    partidoElectos,
    totalElectos,
    $tableElectos,
  };
}

function makeTableEjecutivos(data, title) {
  let $tableElectos = '';
  const _ejecutivos = _.template($('#table-ejecutivos').html());
  if (data.length) {
    $tableElectos = _ejecutivos({
      total: data.length,
      ejecutivos: data,
      title: title || 'SECRETARIOS',
    });
  }

  return {
    $tableElectos,
  };
}

function makeTableDatosEconomicos(data) {
  let $datosEconomicos = '';
  const _datosEconomicos = _.template($('#datosEconomicos').html());
  const _data = {
    ...data,
    censo2012: round(data.censo2012, 0),
    nbi_2012: round(data.nbi_2012),
    satisf_2021: round(data.satisf_2021, 0),
    umbral2021: round(data.umbral2021, 0),
    moder2021: round(data.moder2021, 0),
    indigemt2021: round(data.indigemt2021, 0),
    rectrib2011: round(data.rectrib2011),
    rectrib2019: round(data.rectrib2019),
    transreg2011: round(data.transreg2011),
    transreg2019: round(data.transreg2019),
    ejec2011: round(data.ejec2011),
    ejecporc2011: round(data.ejecporc2011),
    ejec2019: round(data.ejec2019),
    ejecporc2019: round(data.ejecporc2019),
    proy2022: round(data.datosGenerales.POB2022, 0),
    superficie: round(data.datosGenerales.SUP, 0),
    fechaCreacion: data.datosGenerales.CREAC,
    archivo: data.datosGenerales.archivo,
  };
  $datosEconomicos = _datosEconomicos(_data);
  return { $datosEconomicos };
}

function gradoGobernabilidad(cantidad, cantidadTotal) {
  const porcentaje = (cantidad / cantidadTotal) * 100;
  let label, color;

  if (porcentaje < 26) {
    label = 'Ingobernable';
    color = '#A13811';
  } else if (porcentaje < 51) {
    label = 'Baja gobernabilidad';
    color = '#FE9901';
  } else if (porcentaje < 76) {
    label = 'Gobernable';
    color = '#66F430';
  } else {
    label = 'Alta gobernabilidad';
    color = '#2E611B';
  }

  return {
    porcentaje,
    label,
    color,
  };
}

function displayDeptoControl(idEta) {
  const item = electosDepartamento.find((item) => item.id_eta === idEta);

  if (item) {
    const data = {
      eta: item.nombreeta,
      titulo: 'Gobernador',
      autoridad: item.nombre_exautoridad,
      partido: item.sigla,
      porcentaje: item.porcentaje,
      segundaVuelta: item.segunda_vuelta,
    };

    infoControl.update(data);
  } else {
    infoControl.update();
  }
}

function displayMuniControl(idEta) {
  const item = electosMunicipio.find((item) => item.id_eta === idEta);

  if (item) {
    const data = {
      eta: item.depto + ' - ' + item.nombreeta,
      titulo: 'Alcalde',
      autoridad: item.nombre_exautoridad,
      partido: item.sigla,
      porcentaje: item.porcentaje,
    };

    infoControl.update(data);
  } else {
    infoControl.update();
  }
}

$(function () {
  $('.card-radio').on('click', function () {
    $('.card-radio').removeClass('active');
    $(this).addClass('active');
    resetLayers();
    map.setView(latLng, zoom);
    changeGestion(this.dataset.gestion);
  });

  $('#g' + GESTION).trigger('click');

  const $select2 = $('#select2-eta');

  $select2.select2({
    theme: 'bootstrap',
    placeholder: 'Buscar...',
    allowClear: true,
  });

  $select2.on('select2:open', function () {
    $('.select2-search__field')[0].focus();
  });

  $select2.on('change', function () {
    if (this.value == '') {
      resetLayers();
      map.setView(latLng, zoom);
      return;
    }
    const $opt = $select2.find('option:selected');
    if ($opt.data('type') == 'Departamento') {
      const layer = dataDepartamentos[this.value];
      map.fitBounds(layer.getBounds());
      makeMuniLayer(layer.feature.properties.nom_dpto);
      displayDeptoInfo(this.value);
    } else {
      const deptoCode = '90' + this.value.substr(1, 1);
      const dptoLayer = dataDepartamentos[deptoCode];
      makeMuniLayer(dptoLayer.feature.properties.nom_dpto).then(() => {
        displayMuniInfo(this.value);
        const muniLayer = dataMunicipios[this.value];
        map.fitBounds(muniLayer.getBounds());
        muniLayer.setStyle(selectStyle);
        selectedLayer = muniLayer;
      });
    }
  });

  $.getJSON('api/listado-etas/', function ({ data }) {
    $select2.empty();

    listadoEtas = data;

    listadoEtas.forEach((el, idx) => {
      if (el.tipo === 'Gobernacion') {
        $select2.append(`<option value="${el.id}" data-type="Departamento">${el.depto}</option>`);
      } else {
        $select2.append(
          `<option value="${el.id}" data-type="Municipio" data-depto="${el.depto}">${el.depto} - ${el.eta}</option>`
        );
      }
    });

    $select2.val('');
  });

  // changeGestion(GESTION);
});

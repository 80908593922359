require('./bootstrap');

if (document.getElementById('map')) {
  require('./pages/map');
}

$(function () {
  $('body').on('click', '.btn-async-modal', function (event) {
    event.preventDefault();
    const { murl, msize } = $(this).data();

    async_modal(murl, msize);
  });

  // $('body').on('click', '.close-modal', function (event) {
  //   event.preventDefault();
  //   $('#async-modal').modal('hide');
  //   $('#async-modal .modal-content').empty();
  // });

  function async_modal(url, size) {
    const modal_size = `modal-${size}` || 'modal-lg';
    const $modal = $('#async-modal');
    // modal_functions();
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'html',
      beforeSend: function () {
        $modal.modal('show');
        $modal.html(
          '<div class="modal-dialog"><i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i><div>Cargando, por favor espera...</div></div>'
        );
      },
      success: function (html) {
        $modal.html(
          `<div class="modal-dialog ${modal_size}" role="document">
            <div class="modal-content">${html}</div>
          </div>`
        );
      },
    });
  }
});
